import React from 'react';
import ReactDOM from "react-dom";
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import './index.css'
import 'leaflet/dist/leaflet.css';

import MainPage from './pages/main_page/MainPage';
import { RGBGuesser } from './pages/rgb_guesser/RGBGuesser.js'
import { LayoutV1 as RasoiLayout } from './pages/rasoi/src/layout/layout_v1.js'
import { RunYourCity } from './pages/run_your_city/home.js'
import Idioms from './pages/idioms/Idioms.js';
import { Ads } from './pages/ads/Ads.js';

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />,
  },
  {
    path: "/rgb",
    element: <RGBGuesser />,
  },
  {
    path: "/rasoi",
    element: <RasoiLayout />,
  },
  {
    path: "/run_your_city",
    element: <RunYourCity />,
  },
  {
    path: "/idioms",
    element: <Idioms />,
  },
  {
    path: "/ads",
    element: <Ads />,
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";
import './MainPage.css';

import SearchableList from '../../components/searchable_list.js'

const page_list = [
  { 'title': '/rgb', 'link': '/rgb' },
  { 'title': '/rasoi', 'link': '/rasoi' },
  { 'title': '/birdbot', 'link': 'https://birdbot.co.uk' },
  { 'title': '/run_your_city', 'link': '/run_your_city' },
  { 'title': '/idioms', 'link': '/idioms' },
  { 'title': '/ads', 'link': '/ads' },
];

class MainPage extends React.Component {
  render() {
    return (
      <div className="MainPage">
        <header className="main_page_header">
          <h1 className="MainTitle">euan's website</h1>
        </header>
        <div className="main_body">
          <SearchableList data={page_list} />
        </div>
      </div>
    );
  }
}


export default MainPage;

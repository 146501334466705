import React, { useEffect, useState } from "react";
import "./RGBGuesser.css"

import { API_URL } from '../../constants.js';

class PreviousScore extends React.PureComponent {
  render() {
    return (
      <h1>{this.props.score}</h1>
    )
  }
}

const LeaderboardRow = (props) => {
  return (
    <div className="leaderboard_row">
      <div className="list_grid_item"><p className="list_index list_grid_item">{props.index}</p></div>
      <div className="list_grid_item"><p className="list_name list_grid_item">{props.name}</p></div>
      <div className="list_grid_item"><p className="list_score list_grid_item">{props.score}</p></div>
    </div >

  )
}

const EnterUsername = (props) => {
  return (
    <div className="enter_name">
      <div className="enter_name_title_container">
        <p className="enter_name_title">Enter Name</p>
      </div>
      <div className="enter_name_input_container">
        <input name='name_input_box' className="enter_name_input" onBlur={e => props.setUsername(e.target.value)} />
      </div>
    </div>
  )
}

const Leaderboard = (props) => {
  const [leaderboard_results, setLeaderboardResults] = useState(props.leaderboard_results);

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    fetch(API_URL + '/rgb/scores', requestOptions)
      .then(response => response.json())
      .then(data => setLeaderboardResults(data))
  }, [props.final_result])

  return (
    <div className="leaderboard">
      <ul className="leaderboard_list">
        {leaderboard_results.map((item, index) => <LeaderboardRow key={index} index={index + 1} name={item['name']} score={item['score']} />)}
        {props.show_scores &&
          <ul className="result_list">
            <h1 className="final_result_text">Overall Result: {props.final_result}</h1>
            <button className="next_game rgb_button" name="next_game" onClick={props.nextGame}>Next Game</button>
          </ul>
        }
      </ul>
    </div>
  )
}

export const RGBGuesser = () => {
  const [r, setRedValue] = useState(0)
  const [g, setGreenValue] = useState(0)
  const [b, setBlueValue] = useState(0)
  const [user_r, setUserRedValue] = useState(0)
  const [user_g, setUserGreenValue] = useState(0)
  const [user_b, setUserBlueValue] = useState(0)
  const [results_mode, setResultsMode] = useState(false)
  const [previous_score, setPreviousScore] = useState(0)
  const [previous_scores, setPreviousScores] = useState([])
  const [result_count, setResultCount] = useState(0);
  const [final_result, setFinalResult] = useState(0);
  const [show_final_result, setShowFinalResult] = useState(false);
  const [show_leaderboard, setShowLeaderboard] = useState(false);
  const [leaderboard_results, setLeaderboardResults] = useState([]);
  const [username, setUsername] = useState(null);

  const randomInt = (max) => {
    return Math.floor(Math.random() * max)
  }

  const submitButton = () => {
    if (results_mode) {
      console.log("Refresh button pressed")
      setNewColours()
    }
    else {
      console.log("Submit button pressed")
      checkResults()
    }

    if (result_count >= 5) {
      calculateFinalResults()
    }
  }

  const toggleLeaderboard = () => {
    console.log("toggle leaderboard")
    setShowLeaderboard(!show_leaderboard);
  }

  const calculateFinalResults = () => {
    const sum = previous_scores.reduce((a, b) => a + b, 0);
    const avg = Math.round((sum / previous_scores.length) || 0, 2);
    setFinalResult(avg)
    storeFinalScore(avg)
    setShowFinalResult(true)
  }

  const storeFinalScore = (final_score) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'name': username,
        'score': final_score
      })
    };

    fetch(API_URL + '/rgb/scores', requestOptions)
      .then(response => response.json())
      .then(data => setLeaderboardResults(data))
  }

  const checkResults = () => {
    setResultsMode(true)
    console.log("User entered " + user_r + "," + user_g + "," + user_b + " Results were: " + r + "," + g + "," + b)

    let score = calculateScore()
    setPreviousScores([...previous_scores, score])
    setPreviousScore(score)

    console.log("Overall score ", score, "%")
    setResultCount(result_count + 1)
  }

  const setNewColours = () => {
    setUserRedValue(0)
    setUserGreenValue(0)
    setUserBlueValue(0)
    setRedValue(randomInt(255))
    setGreenValue(randomInt(255))
    setBlueValue(randomInt(255))
    setResultsMode(false)
  }

  const calculateScore = () => {
    let r_score = Math.abs(r - user_r) ** 2
    let g_score = Math.abs(g - user_g) ** 2
    let b_score = Math.abs(b - user_b) ** 2

    let euclidean_distance = Math.sqrt(r_score + g_score + b_score)
    console.log("R Score: ", r_score)
    console.log("G Score: ", g_score)
    console.log("B Score: ", b_score)
    return Math.round(Math.abs(100 * (1 - (euclidean_distance / 255))));
  }

  const nextGame = () => {
    setPreviousScores([])
    setNewColours()
    setResultCount(0)
    setShowFinalResult(false)
  }

  useEffect(() => {
    setNewColours();
    // eslint-disable-next-line
  }, [])

  return (
    <div className="rgb_guesser" >
      <div className="rgb_main">
        <div className="main_box" style={{ backgroundColor: `rgb(${r}, ${g}, ${b})` }}>
          {results_mode && <span className="user_result_box" style={{ backgroundColor: `rgb(${user_r}, ${user_g}, ${user_b})` }}><h1 className="guess_text">Your Guess</h1></span>}
          {results_mode && <div className="result_box"><h1 className="guess_text">Result</h1></div>}
          <h1 className="results_text" style={{ color: 'white' }}>{results_mode ? r + "," + g + "," + b : ''}</h1>
        </div>
        {(show_leaderboard || show_final_result) && <Leaderboard final_result={final_result} nextGame={nextGame} show_scores={show_final_result} leaderboard_results={leaderboard_results} />}
        {username === null && <EnterUsername setUsername={setUsername} />}
        <div className="input_div">
          <label className="input_label" style={{ color: `rgb(${r}, ${g}, ${b})` }}>
            R:
            <input placeholder={user_r} name='user_r' className="input_box" onBlur={e => setUserRedValue(e.target.value)} />
          </label>
          <label className="input_label" style={{ color: `rgb(${r}, ${g}, ${b})` }}>
            G:
            <input placeholder={user_g} name='user_g' className="input_box" onBlur={e => setUserGreenValue(e.target.value)} />
          </label>
          <label className="input_label" style={{ color: `rgb(${r}, ${g}, ${b})` }}>
            B:
            <input placeholder={user_b} name='user_b' className="input_box" onBlur={e => setUserBlueValue(e.target.value)} />
          </label>
        </div>
        <button className="submit_button rgb_button" name="submit" onClick={submitButton}>{results_mode ? "Reload" : "Submit"}</button>
        <h1 className="previous_score">{previous_score}%</h1>
        <h3 className="credit">Idea credit goes to Cameron</h3>
      </div>
      <div className="side_bar">
        <h1>{result_count} / 5</h1>
        {previous_scores.map(score => <PreviousScore score={score} />)}
      </div>
      <div className="right_side_bar">
        <button className="show_leaderboard_button rgb_button" onClick={toggleLeaderboard}>Show Leaderboard</button>
        {username !== null && <h1>{username}</h1>}
      </div>
    </div >
  )
};

